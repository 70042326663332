<template>
    <div>
      <h1>新人有礼推荐页面</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NewUserGift',
  }
  </script>