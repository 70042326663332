import { createApp } from 'vue'
import About from './About.vue'
import Home from '@/pages/home/Home.vue'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    { path: '/about', name:'about',component: About }, // 这里需要一个逗号
    { path: '/home', name: 'Home', component: Home }
 
  ]
const router = createRouter({
    history: createWebHistory(),
    routes})

createApp(About).use(router).mount('#app')