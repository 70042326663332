<template>
    <div>
      <h1>About Page</h1>
      <DownloadApp/>
      <button @click="goHome">返回首页</button>
      <p>参数: {{ $route.query.ref }}</p>

      <button @click="downloadHAP">安卓下载</button>
      <button @click="downloadHAP">iOS下载</button>
      <button @click="downloadHAP">鸿蒙下载</button>

     </div>   
  </template>
  
  <script>
  import DownloadApp from '@/components/DownloadApp.vue'
  
  export default {
    name: 'About',
    components: {
      DownloadApp
    },
    methods: {  
      goHome() {
        // this.$router.push('/home')
        // window.location.href = this.$router.resolve({ name: 'Home', query: { ref: '10001011' } }).href;     

        let routerData = this.$router.resolve({ name: 'Home', query: { ref: '10001011' } })    
        window.open('https://m.xcpt.fun' + routerData.href, '_blank') 


      }
    } 
   }
 
  </script>
  