<template>
    <div>
      <h1>Home Page</h1>
      <NewUserGift/>
      <button @click="goToDownloadApp">尝试打开APP</button>
      <p>参数: {{ $route.query }}</p>
     </div>
  </template>
  
  <script>
  import NewUserGift from '@/components/NewUserGift.vue'
   export default {
    name: 'Home',
    components: {
      NewUserGift
    },
    methods: {
      goToDownloadApp() {
       let routerData = this.$router.resolve({ name: 'About', query: { ref: 'newUserGift' } })    
       window.open('https://d.xcpt.fun' + routerData.href, '_blank')  
    }
}
  }
  </script>
  